import { sellerHeader } from "../animation/RoutesAnimations";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from 'react'
import TopRight from "./components/TopRight";
import TopLeft from "./components/TopLeft";
import React from "react";
import MainTable from "../pages/admin-dashboard/admin-tabs/users/MainTable";
import ButtonComponent from "../components/elements/ButtonComponent";
import RequestPopup from "../components/Pages/popups/RequestPopup";
import { axiosPrivate } from "../api/axios";
import { GET_CUSTOMER_PROFILE, STORE_SETTINGS, TIME_TABLE_CUSTOMERS, WORK_TYPE } from "../api/apiClient";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import GridLoader from "react-spinners/GridLoader";
import TableHeader from "../pages/admin-dashboard/admin-tabs/users/TableHeader";

const User = () => {

  const [tableData, setTableData] = useState<any>([
    { cheque_number: 'aasas', tracking_code: "sss", full_name: 'sdsd', cheque_amount: '342342', tag_number: '67344' },
    { cheque_number: 'aasas', tracking_code: "sss", full_name: 'sdsd', cheque_amount: '342342', tag_number: '67344' }

  ]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [popup, setPopup] = useState<boolean>(false);
  const params: any = useParams();
  const store_id = params?.customer_uuid;
  const [profileData, setPofileData] = useState<any>(undefined);
  const [profileDataLoading, setProfileDataLoading] = useState<boolean>(false);
  const [inputDate, setInputDate] = useState<any>(undefined);
  const [tableTime, setTableTime] = useState<any>(undefined);
  const [storeName, setStoreName] = useState('ساویز');
  const [timeTableData, setTimeTableData] = useState<any>(undefined);
  const [loadingTimeTable, setLoadingTimeTable] = useState<boolean>(false);
  const [workTypeData, setWorkTypeData] = useState<any>(undefined);
  const [loadingWorkTypeData, setLoadingWorkTypeData] = useState<boolean>(false);
  const [timeUnit, setTimeUnit] = useState<any>(undefined);
  const [service, setService] = useState<any>(undefined);
  const [timeDiff, setTimeDiff] = useState<any>(undefined);
  const myRef = useRef<any>(null);
  const [settingsData, setSettingsData] = useState<any>(undefined);
  const [price, setPrice] = useState<any>(undefined)
  const simpleColJson = [
    // { header: "نام خدمت", field: "name" },
    { header: 'بازه زمانی', field: 'timeInterval' }
  ];

  function onClose() {
    setPopup(false)
  }

  const getProfile = () =>
    axiosPrivate
      .get(
        GET_CUSTOMER_PROFILE(store_id),

        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.customer_profile);

  const profileMutate = useMutation({
    mutationFn: getProfile,
    onSuccess(data, variables, context) {
      setPofileData(data);
      setStoreName(data?.name);
      setProfileDataLoading(false);
    },
    onMutate() {
      setProfileDataLoading(true);
    },
    onError() {
      setProfileDataLoading(false);
    },
  });

  useEffect(() => {
    profileMutate.mutate();
  }, [])

  const detailBody = (data: any, rowIndex: any) => {

    return (
      <React.Fragment>
        <p className="flex flex-1 w-[125px] px-2">
          <ButtonComponent
            onClick={() => { if (data?.status === 'NONE') { setPopup(true); setTimeUnit(data?.time_unit) } }}
            disabled={data?.status === 'ACTIVE' || data?.status === 'CONFILICT' || data?.status === 'PENDING'}
            hasIcon
            icon={
              <img
                className=" h-[24px] w-[24px]"
                src="../icons/tik.svg"
                alt="not found"
              />
            }
            bgColor=""
            className="hover:bg-[#B4E380]"
            variant={"outlined"}
          >
            ثبت نوبت
          </ButtonComponent>
        </p>
      </React.Fragment>
    );
  };

  function timeCalculator(time: any, time_unit: any) {
    var min_unit1 = time * (time_unit - 1)
    var hours1 = Math.floor(min_unit1 / 60)
    var minutes1 = min_unit1 % 60
    hours1 = hours1 + 6
    var min_unit2 = time * (time_unit)
    var hours2 = Math.floor(min_unit2 / 60)
    var minutes2 = min_unit2 % 60
    hours2 = hours2 + 6
    return `${hours1}:${minutes1} تا ${hours2}:${minutes2}`
  }


  const getTimeTable = () =>
    axiosPrivate
      .get(
        TIME_TABLE_CUSTOMERS(inputDate, store_id),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.table);

  const timeTableMutate = useMutation({
    mutationFn: getTimeTable,
    onSuccess(data, variables, context) {
      let tableD = []
      let dataIndex = 0
      var conf = false
      for (let i = Math.ceil(120 / tableTime); i < 1080 / tableTime; i++) {
        if (data?.length === 0) {
          tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "NONE", time: tableTime, time_unit: i + 1 })
        } else {


          var confd = false
          for (let k = 0; k < data?.length; k++) {
            if (data[k].time !== tableTime) {
              confd = true
              break
            }
          }

          var confd2 = false
          for (let k = 0; k < data?.length; k++) {
            if (data[k].time === tableTime) {
              confd2 = true
              break
            }
          }


          if (confd) {
            // tableD.push({store_name : storeName, timeInterval: timeCalculator(tableTime, i+1), status : "NONE", time: tableTime, time_unit: i+1})

            var time_conflict = false
            // // all_day_times = Time_Table.objects.filter(sid=customer_sid,date=day_date)
            // // for item in all_day_times:

            for (let k = 0; k < data?.length; k++) {

              var minList = []
              for (let j = data[k].time * (data[k].time_unit - 1); j < data[k].time * data[k].time_unit; j++) {
                minList.push(j)
              }

              for (let j = tableTime * (i); j < tableTime * (i + 1); j++) {
                if (minList.includes(j)) {
                  dataIndex = k
                  time_conflict = true
                  break
                }

              }

            }



            if (time_conflict) {
              
              if (data[dataIndex].time !== tableTime) {
                tableD.push({ store_name: storeName, name: data[dataIndex].name, status: "ACTIVE", timeInterval: timeCalculator(tableTime, i + 1), time: data[dataIndex].time, time_unit: data[dataIndex].time_unit, user_fullname: data[dataIndex].user_fullname, user_phone_number: data[dataIndex].user_phone_number })
              } else {
                tableD.push({ ...data[dataIndex], timeInterval: timeCalculator(tableTime, i + 1) })
              } time_conflict = false
            }
            // else if (dataIndex + 1 < data.length) {

            //   if ((i + 1) !== data[dataIndex + 1].time_unit){
            //     //newhere
            //     var time_conflict2 = false

            //     var minList = []
            //     for (let j = data[dataIndex + 1].time * (data[dataIndex + 1].time_unit - 1) ; j < data[dataIndex + 1].time * data[dataIndex + 1].time_unit ; j ++) {
            //       minList.push(j)
            //     }

            //     for (let j = tableTime * (i + 1) ; j < tableTime * (i + 2) ; j ++) {
            //       console.log(j,'j')
            //       if (minList.includes(j)) {

            //           time_conflict2 = true
            //           break
            //         }

            //     }

            //     if (time_conflict2) {
            //       tableD.push({store_name : storeName, name: data[dataIndex + 1].name, status : "CONFLICT", timeInterval: timeCalculator(tableTime, i+1), time: data[dataIndex + 1].time, user_fullname: data[dataIndex + 1].user_fullname, user_phone_number: data[dataIndex + 1].user_phone_number})
            //     } else {
            //       tableD.push({store_name : storeName, timeInterval: timeCalculator(tableTime, i+1), status : "NONE", time: tableTime, time_unit: i+1})
            //     }
            //     dataIndex = dataIndex + 1;
            //   }
            //   else {
            //     tableD.push({...data[dataIndex + 1], timeInterval: timeCalculator(tableTime, i+1)})
            //     if (dataIndex + 2 < data.length) {
            //       dataIndex = dataIndex + 2;
            //     } else {
            //       dataIndex = dataIndex + 1;
            //     }

            //   }
            // }
            else {
              tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "NONE", time: tableTime, time_unit: i + 1 })
            }
          }
          else if (confd2) {

            var check = false
            for (let k = 0; k < data?.length; k++) {
              if (data[k].time === tableTime) {
                if (data[k].time_unit === i + 1) {
                  tableD.push({ ...data[k], timeInterval: timeCalculator(tableTime, i + 1) })
                  check = true
                  break
                }
              }
            }
            if (!check) {
              tableD.push({ store_name: storeName, timeInterval: timeCalculator(tableTime, i + 1), status: "NONE", time: tableTime, time_unit: i + 1 })
            }

            
          }
          else {
            tableD.push({ ...data[dataIndex], timeInterval: timeCalculator(tableTime, i + 1) })

          }

          var inTime = false;

          // if (dataIndex + 1 < data.length && data[dataIndex].time * data[dataIndex].time_unit < tableTime * (i + 1)) {
          //   dataIndex = dataIndex + 1;
          // }
        }
      }

      setTimeTableData(tableD);
      setLoadingTimeTable(false);
      setTimeout(function () {
        timeTableMutate.mutate();
      }, 60000);
    },
    onMutate() {
      setLoadingTimeTable(true);
    },
    onError() {
      setLoadingTimeTable(false);
    },
  });

  useEffect(() => {
    if (inputDate !== undefined && tableTime !== undefined)
      timeTableMutate.mutate();
  }, [inputDate, tableTime])

  const getWorkTypes = () =>
    axiosPrivate
      .get(
        WORK_TYPE(store_id),

        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.work_types);

  const workTypeMutate = useMutation({
    mutationFn: getWorkTypes,
    onSuccess(data, variables, context) {
      setWorkTypeData(data);
      setLoadingWorkTypeData(false);
    },
    onMutate() {
      setLoadingWorkTypeData(true);
    },
    onError() {
      setLoadingWorkTypeData(false);
    },
  });

  useEffect(() => {
    workTypeMutate.mutate();
  }, [])

  function dateSuber() {
    var today = new Date().getTime();
    var dat = new Date(inputDate).getTime();
    const diffTime = (dat - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setTimeDiff(diffDays)
  }

  useEffect(() => {
    dateSuber()
  }, [inputDate])



  const getSettings = () =>
    axiosPrivate
      .get(
        STORE_SETTINGS(store_id),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.store_settings);

  const settingsMutate = useMutation({
    mutationFn: getSettings,
    onSuccess(data, variables, context) {
      setSettingsData(data);

    },
    onMutate() {

    },
    onError() {

    },
  });

  useEffect(() => {
    settingsMutate.mutate();
  }, [])

  return (
    <motion.div
      variants={sellerHeader}
      initial="initial"
      animate="final"
      className="overflow-x-hidden min-h-screen "
    >
      <div className="flex flex-col w-full justify-center items-center">
        {profileDataLoading ? (<>
          <GridLoader
            color={'#002db4'}
            loading={true}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="my-32"
          />
        </>) : (<>
          <div className="flex max-sm:flex-col sm:flex-col lg:flex-row w-full justify-center items-center mt-[64px] max-sm:mr-0 sm:mr-0 lg:px-40 lg:mr-[50px]">
            <TopRight profileData={profileData} myRef={myRef} />
            {/* abot */}
            <TopLeft profileData={profileData} myRef={myRef} />
          </div>
        </>)}

        <div className="h-1 w-11/12 bg-primary-800 opacity-[50%] rounded-sm mt-[50px]"></div>

        <div style={{ scrollbarWidth: 'none' }} ref={myRef} className="flex relative max-sm:min-w-[400px] sm:min-w-[400px] md:min-w-[550px] lg:min-w-[700px] mx:min-w-[700px] flex-col min-h-[150px] border border-yellow-500 rounded-lg max-h-[450px] scroll overflow-y-scroll mb-10 justify-start items-center max-sm:mt-6 sm:mt-10 md:mt-16 lg:mt-16 p-3">
          <TableHeader user pb0 setPrice={setPrice} setService={setService} workTypeData={workTypeData} setTableTime={setTableTime} setInputDate={setInputDate} />
          <MainTable
            notCheckbox
            marginTop={"0px"}
            pageNumber={1}
            dataKey=""
            Data={timeTableData || []}
            loadingData={loadingTimeTable}
            // hasRowNum
            simpleColsJson={simpleColJson}
            // setSelectedDataUseState={setSelectedCustomers}
            // selectedDataUseState={selectedCustomers}
            stateCol
            stateColData={{ header: "وضعیت", field: "state.name" }}
            detailCol
            detailBody={(data: any, { rowIndex }: any) =>
              detailBody(data, rowIndex)
            } />
        </div>

        {popup && <RequestPopup price={price} store_name={profileData?.name} inputDate={inputDate} user_pay_before={settingsData?.user_pay_before} date_number={timeDiff} time={tableTime} time_unit={timeUnit} name={service} store_id={store_id} mutation={timeTableMutate} onClose={onClose} />}

      </div>
    </motion.div>
  );
};

export default User;
