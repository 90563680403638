import Lottie from "react-lottie";
import animationData from "../../../src/jsons/roles.json";
import noticeAnimated from "../../../src/jsons/notice-red.json";
import { IconClick } from "@tabler/icons-react";
import "./bordercss.css"
import Footer from "../../provider/Footer";
const Aboutus = () => {


    const defaultOptions = {
        loop: false,
        animationData: animationData,
        renderer: "svg",
      };

      const defaultOptionsNotice = {
        loop: true,
        animationData: noticeAnimated,
        renderer: "svg",
      };

    return (
        <div className="flex flex-col w-full justify-center items-center max-sm:p-3 sm:p-4 md:p-4 lg:p-14">
              <div className="w-full flex flex-col items-center gap-2 mt-4 border border-gray-100 rounded-lg p-14 m-10 text-center">
                <p className="text-gray-600 text-BodyText/16/Regular">با سلام</p>
                <div className="flex w-full flex-row p-4 text-center justify-center items-center">
                 <p className="text-gray-600 text-BodyText/16/Regular">ما تیم <a href="https://rezerv-sho.ir" className="text-primary-500 text-BodyText/16/Bold">رزرو شو </a>اینجا هستیم تا براتون روند نوبت دهی رو هرچه سریع تر و آسان تر کنیم و بدون هیچ محدودیتی بتونید نوبت های خودتون رو در هر حوضه‌ی کسب و کاری رزرو کنید.</p>
                </div>
                <div>
                </div>
              </div>
            <Footer />
        </div>
    )
}

export default Aboutus;