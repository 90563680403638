

import { useMutation } from "@tanstack/react-query";
import { STORE_SETTINGS } from "../../api/apiClient";
import { axiosPrivate } from "../../api/axios";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import './css/storeSettingCss.css'
const StoreSettings = () => {


  const spring = {
    type: "spring",
    stiffness: 800,
    damping: 30
  };

  const [settingsData, setSettingsData] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [unactiveDays, setUnactiveDays] = useState<any>([])
  const [payBefore, setPayBefore] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);

  const getSettings = () =>
    axiosPrivate
      .get(
        STORE_SETTINGS(),
        {
          headers: {
            // prettier-ignore
            "accept": "application/json",
            // prettier-ignore
            // "Authorization": `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((res) => res.data.customer_settings);

  const settingsMutate = useMutation({
    mutationFn: getSettings,
    onSuccess(data, variables, context) {
      setSettingsData(data);
      setPayBefore(data?.user_pay_before);
      setUnactiveDays(data?.unactive_days);
      setLoading(false);
    },
    onMutate() {
      setLoading(true);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    settingsMutate.mutate();
  }, [])

  const DayCart = ({ index, label }: any) => {

    const spr = {
      type: "spring",
      stiffness: 200,
      damping: 30
    };

    function dayHandler(index: number) {
      var sample = unactiveDays
      if (sample.includes(index)) {
        sample.pop(index)
      } else {
        sample.push(index)
      }
      setUnactiveDays([...sample])
    }

    return (
      <div
        onClick={() => dayHandler(index)} className={`flex cursor-pointer gap-2 justify-center items-center p-2 rounded-lg ${unactiveDays.includes(index) ? `bg-red-50` : `bg-green-50`}`}>
        <p className={`${unactiveDays.includes(index) ? `text-red-700` : `text-green-700`} text-Body-OneLine/16/Regular`}>{label}</p>
        <div className={`switch ${unactiveDays.includes(index) ? `bg-red-400` : `bg-green-400`}`} data-isOn={!unactiveDays.includes(index)} >
          <motion.div className="handle bg-primary-50" layout transition={spr} />
        </div>
      </div>
    )
  }

  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i: any) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };


    const depositHandler = async () => {
      try {
        const response = await axiosPrivate.put(
          STORE_SETTINGS(undefined),JSON.stringify({user_pay_before: !payBefore}),
          {
            headers: {
              // prettier-ignore
              "accept": "application/json",
              // prettier-ignore
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
  
        if (response?.status >= 200 && response?.status < 300) {
          setPayBefore(!payBefore);
        } else {
          console.error(
            "ERROR in supervisor:",
            response.data || response.statusText
          );
        }
      } catch (error) {
      }
    }

  return (
    <div className="flex flex-col gap-6 justify-start items-start w-full">
      <div className="flex flex-row gap-1 justify-center items-center">
        <div onClick={depositHandler} className={`switch cursor-pointer ${payBefore ? `bg-primary-400` : `bg-red-400`}`} data-isOn={payBefore}
        //  onClick={() => setPayBefore(!payBefore)}
        >
          <motion.div className="handle bg-primary-50" layout transition={spring} />
        </div>
        <p>دریافت بیعانه از مشتری</p>
      </div>
      {/* <div className="flex max-sm:flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row w-full gap-6 items-start justify-start">
              <div className="flex gap-6">
                <DayCart index={1} label={'شنبه'} />

                <DayCart index={2} label={'یک شنبه'} />

                <DayCart index={3} label={'دو شنبه'} />
              </div>

              <div className="flex gap-6">
                <DayCart index={4} label={'سه شنبه'} />

                <DayCart index={5} label={'چهار شنبه'} />

                <DayCart index={6} label={'پنج شنبه'} />
              </div>

              <div className="flex gap-6">
                <DayCart index={7} label={'جمعه'} />
              </div>
            </div> */}

      {/* <div className="flex w-full h-[200px] justify-start items-center">
                <div className="flex w-[400px] justify-center items-center">
                      <motion.div
                        layout
                        data-isOpen={isOpen}
                        initial={{ borderRadius: 50 }}
                        className="parent"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <motion.div layout className="child" />
                      </motion.div>
                </div>
            </div> */}
      {/* <motion.svg
        width="300"
        height="300"
        viewBox="0 0 300 300"
        initial="hidden"
        animate="visible"
        className="flex relative"
      >
        <motion.circle
          cx="150"
          cy="150"
          r="150"
          stroke="#ff0055"
          variants={draw}
          custom={1}
          className={'fill-none'}
        /> */}

        {/* <motion.rect
          width="300"
          height="300"
          x="0"
          y="0"
          rx="20"
          stroke="#8E98A8"
          variants={draw}
          custom={5}
          className={'fill-none'}
        /> */}
        {/* <motion.line
          x1="150"
          y1="150"
          x2="300"
          y2="150"
          stroke="#0042FE"
          variants={draw}
          custom={4.5}
          className=" flex absolute z-10"
        />
        <motion.line
          x1="150"
          y1="150"
          x2="255"
          y2="255"
          stroke="#0042FE"
          variants={draw}
          custom={4.5}
          className=" flex absolute z-10"
        /> */}
      {/* </motion.svg> */}
    </div>
  )
}

export default StoreSettings;

