import Lottie from "react-lottie";
import animationData from "../../../src/jsons/roles.json";
import noticeAnimated from "../../../src/jsons/notice-red.json";
import { IconClick } from "@tabler/icons-react";
import "./bordercss.css"
import Footer from "../../provider/Footer";
const Roles = () => {


    const defaultOptions = {
        loop: false,
        animationData: animationData,
        renderer: "svg",
      };

      const defaultOptionsNotice = {
        loop: true,
        animationData: noticeAnimated,
        renderer: "svg",
      };

    return (
        <div className="flex flex-col w-full justify-center items-center max-sm:p-3 sm:p-4 md:p-4 lg:p-10">
            <div className="flex w-full justify-center items-center pr-[1px] animated-b-yellow z-10">
            <div className="flex flex-col w-full gap-4 p-2 z-20 bg-white">
            <div className="flex z-20 m-2 cursor-pointer">
                <Lottie options={defaultOptions} height={70} width={70}/>
            </div>
            <p className="text-gray-950 text-Banner/44/Bold">قوانین</p>

            <div className="p-2 justify-start items-center">
                <p className="text-gray-900 text-BodyText/16/Medium">با سلام خدمت تمامی عزیزانی که از سایت <a className="text-primary-700" href="https://rezerv-sho.ir">رزرو شو</a> استفاده می‌کنند و یا تازه وارد سایت ما شده‌اند. امیدواریم با استفاده از خدمات سایت ما، روند ثبت نوبت کسب و کار شما سریع تر شود.</p>
                <p className="text-gray-900 text-BodyText/16/Medium">در ادامه به بررسی شرایط و قوانین و همچنین هزینه‌های مربوط به رزرو نوبت می‌پردازیم.</p>
            </div>
            <div className="flex justify-start items-start gap-1">
                <p className="text-red-600 text-BodyText/16/Bold">*</p>
                <p className="text-gray-900 text-BodyText/16/Regular">قرار دادن زمان‌ها در کنار هم، توسط خدمات دهنده صورت می‌گیرد. <a className="text-primary-700" href="https://rezerv-sho.ir">رزرو شو</a> در حال بررسی راه حلی برای این موضوع می‌باشد.</p>
            </div>
            <div className="flex justify-start items-start gap-1">
                <p className="text-red-600 text-BodyText/16/Bold">*</p>
                <p className="text-gray-900 text-BodyText/16/Regular">حذف نوبت رزرو شده باعث برگشت وجه <b className="text-red-600">نمی‌شود</b>.</p>
            </div>
            <div className="flex justify-start items-start gap-1">
                <p className="text-red-600 text-BodyText/16/Bold">*</p>
                <p className="text-gray-900 text-BodyText/16/Regular">برداشت پول از حساب کاربری <b className="text-primary-900">2</b> تا <b className="text-primary-900">3</b> روز کاری زمان خواهد برد.</p>
            </div>
            <div className="flex justify-start items-start gap-1">
                <p className="text-red-600 text-BodyText/16/Bold">*</p>
                <p className="text-gray-900 text-BodyText/16/Regular">حساب منفی باعث <b className="text-red-600">قفل شدن</b> حساب کاربری فرد می‌شود.</p>
            </div>
            <div className="flex justify-start items-start gap-1">
                <p className="text-red-600 text-BodyText/16/Bold">*</p>
                <p className="text-gray-900 text-BodyText/16/Regular">حداقل مبلغ شارژ کیف پول و هزینه ثبت شده برای خدمات مبلغ <b className="text-primary-900">50,000</b> هزار تومان می‌باشد.</p>
            </div>
            <div className="flex flex-col justify-center items-start gap-2">
                <p className="text-gray-950 text-HeadLine/24/ExtraBold">هزینه‌های خدمات دهنده</p>
                <div className="flex justify-start items-center">
                    <div className="flex z-20 m-2 cursor-pointer">
                        <Lottie options={defaultOptionsNotice} height={25} width={25}/>
                    </div>
                    <p className="text-gray-900 text-BodyText/16/Regular">ثبت نوبت توسط خدمات دهنده مبلغ <b className="text-primary-900">1,000</b> تومان هزینه دارد.</p>
                </div>
                <div className="flex justify-start items-center">
                    <div className="flex z-20 m-2 cursor-pointer">
                        <Lottie options={defaultOptionsNotice} height={25} width={25}/>
                    </div>
                    <p className="text-gray-900 text-BodyText/16/Regular">ثبت نوبت توسط مشتری بدون پیش پرداخت مبلغ <b  className="text-primary-900">4,000</b> تومان هزینه دارد.</p>
                </div>
                <div className="flex justify-start items-center">
                    <div className="flex z-20 m-2 cursor-pointer">
                        <Lottie options={defaultOptionsNotice} height={25} width={25}/>
                    </div>
                    <p className="text-gray-900 text-BodyText/16/Regular">ثبت نوبت توسط مشتری با پیش پرداخت بین <strong className="text-primary-900">10,000</strong> تا <strong className="text-primary-900">60,000</strong> تومان هزینه دارد.</p>
                </div>
            </div>

            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Roles;