import Lottie from "react-lottie";
import animationData from "../../../../../src/jsons/Loading333.json";
import moment from "jalali-moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { convertToJalali } from "../../../../utils/useConvertToJalali";
import useAddComma from "../../../../components/hooks/useAddComma";
import BarLoader from "react-spinners/BarLoader";

interface ButtonProps {
  Data: any;
  loadingData: boolean;
  simpleColsJson: any;
  dateCol?: boolean;
  detailCol?: boolean;
  stateCol?: boolean;
  initialCol?: boolean;
  dateColData?: any;
  detailColData?: any;
  hasRowNum?: boolean;
  stateColData?: any;
  initialColData?: any;
  setSelectedDataUseState?: any;
  selectedDataUseState?: any;
  dataKey?: string;
  notCheckbox?: boolean;
  detailBody: any;
  colorCol?: boolean;
  pageNumber: any;
  marginTop?: string;
}
// complete one

const MainTable: React.FC<ButtonProps> = ({
  Data,
  dataKey,
  marginTop,
  colorCol,
  simpleColsJson,
  notCheckbox,
  hasRowNum,
  loadingData = true,
  dateCol = false,
  detailCol = false,
  stateCol = false,
  initialCol = false,
  dateColData,
  detailColData,
  stateColData,
  initialColData,
  setSelectedDataUseState,
  selectedDataUseState,
  detailBody,
  pageNumber,
}) => {
  const stateBody = (data: any) => {
    var bg_color = "";
    if (data?.state?.tag_color) {
      bg_color = data?.state?.tag_color;
    } else {
      bg_color = data?.state?.button_color;
    }
    return (
      <React.Fragment>
        <div className={`flex`}>
        {(data.status === 'NONE') ? (
          <p className="max-sm:px-2 sm:px-3 lg:px-3 py-1 bg-gray-200 text-gray-700 max-sm:text-BodyText/12/Medium sm:text-BodyText/14/Medium lg:text-BodyText/14/Medium rounded-xl">آزاد</p>
        ) : (data.status === 'ACTIVE') ? (
          <p className="max-sm:px-2 sm:px-3 lg:px-3 py-1 bg-primary-200 text-primary-500 max-sm:text-BodyText/12/Medium sm:text-BodyText/14/Medium lg:text-BodyText/14/Medium rounded-xl">رزرو شده</p>
        ) : (data.status === 'PENDING') ? (
          <p className="max-sm:px-2 sm:px-3 lg:px-3 py-1 bg-yellow-100 text-yellow-500 max-sm:text-BodyText/12/Medium sm:text-BodyText/14/Medium lg:text-BodyText/14/Medium rounded-xl">در انتظار</p>
        ) : (
          <p className="max-sm:px-2 sm:px-3 lg:px-3 py-1 bg-red-100 text-red-500 max-sm:text-BodyText/12/Medium sm:text-BodyText/14/Medium lg:text-BodyText/14/Medium rounded-xl">مغایرت</p>
        )}
        </div>
      </React.Fragment>
    );
  };

  // function dateConverter(date: string) {
  //   var dateList = date?.split("-");
  //   var result = "";
  //   const monthList = [
  //     "فروردین",
  //     "اردیبهشت",
  //     "خرداد",
  //     "تیر",
  //     "مرداد",
  //     "شهربور",
  //     "مهر",
  //     "آبان",
  //     "آذر",
  //     "دی",
  //     "بهمن",
  //     "اسفند",
  //   ];
  //   result =
  //     result +
  //     dateList[2] +
  //     " " +
  //     monthList[Number(dateList[1]) - 1] +
  //     " " +
  //     dateList[0];
  //   return result;
  // }

  const addCama = useAddComma();

  const baseBody = (data: any, varName: string) => {
    return (
      <React.Fragment>
        {varName === "debt_amount" ||
          varName === "amount" ||
          varName === "cheque_amount" ||
          varName === "total_penalty" ||
          varName === "" ? (
          <>
            <p className="flex flex-1 w-full whitespace-nowrap break-keep text-right text-gray-500 text-Body-OneLine/14/Regular">
              {addCama(data[varName])}
            </p>
          </>
        ) : (
          <>
            {(String(data[varName]).length <= 17) ? (<>
              <p className="flex flex-1 w-full whitespace-nowrap break-keep text-right text-gray-500 text-Body-OneLine/14/Regular">
                {(data[varName] === null) || (data[varName] === undefined) ? '--' : data[varName]}
              </p>
            </>) : (<>
              <p data-tooltip-target="tooltip-default" className="flex flex-1 w-full whitespace-nowrap break-keep text-right text-gray-500 text-Body-OneLine/14/Regular">
                {truncateText(data[varName], 17)}
              </p>

            </>)}
          </>
        )}
      </React.Fragment>
    );
  };

  const initialMBody = (data: any) => {
    return (
      <React.Fragment>
        <p className=" text-ellipsis w-full whitespace-nowrap text-right text-gray-500 text-Body-OneLine/12/Regular">
          {/* {item?.initial_message} */}
          {truncateText(data?.initial_message, 12)}
        </p>
      </React.Fragment>
    );
  };

  const truncateText = (text: string, length: any, indicator = "...") => {
    if (!text || text?.length <= length) return text;
    return text?.substring(0, length) + indicator;
  };

  const colorColBody = (data: any) => {
    return (
      <React.Fragment>
        <div
          style={{
            backgroundColor: data.state_color.color,
            borderColor: data.state_color.color,
          }}
          className={`flex rounded-full border-r-4 flex-1 w-[5px] h-14  pr-0`}
        ></div>
      </React.Fragment>
    );
  };

  const dateBody = (data: any, field: any) => {
    return (
      <React.Fragment>
        {data[field] === "TODAY" ? (
          <p className="flex text-ellipsis break-keep flex-1 w-full whitespace-nowrap text-right text-gray-500 text-Body-OneLine/12/Regular">
            امروز
          </p>
        ) : (
          <p className="flex text-ellipsis break-keep flex-1 w-full whitespace-nowrap text-right text-gray-500 text-Body-OneLine/12/Regular">
            {convertToJalali(data[field])}
          </p>
        )}
      </React.Fragment>
    );
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  return (
    <>
      <div style={{marginTop:marginTop}} className={`flex flex-col`}>
        <div className={`flex flex-col w-full`}>
          {/* // other rows */}
          {loadingData ? (
            <div className="flex w-[500px] items-start justify-start">
            <BarLoader 
            color={'#023E8A'}
            loading={true}
            aria-label="Loading Spinner"
            data-testid="loader"
            width={500}
            className=""
            />
            </div>
          ) : Data?.length !== 0 ? (
            <>
              {/* https://www.primefaces.org/primereact-v8/datatable/ */}

              <DataTable
                className=" border-t border-gray-200 "
                rows={10}
                value={Data}
                filterDisplay="menu"
                onSelectionChange={(e) =>
                  setSelectedDataUseState && setSelectedDataUseState(e.value)
                }
                selection={selectedDataUseState}
                rowHover
                dataKey={dataKey}
                tableStyle={{ minWidth: "20rem", maxWidth: "250rem" }}
              >
                {!notCheckbox && (
                  <Column
                    className="h-[50px] border-t border-gray-200"
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                  ></Column>
                )}

                {hasRowNum && pageNumber && (
                  <Column
                    className="h-[50px] border-t border-gray-200"
                    field=""
                    header="ردیف"
                    body={(data: any, { rowIndex }) => (
                      <div className="flex flex-1 w-full whitespace-nowrap break-keep text-center justify-center items-center text-gray-500 text-Body-OneLine/14/Regular">
                        {((pageNumber - 1) * 10) + rowIndex + 1}
                      </div>
                    )}
                  ></Column>
                )}

                {colorCol && (
                  <Column
                    className="h-[50px] w-[50px] border-t border-gray-200"
                    field={""}
                    header={""}
                    headerStyle={{ width: "3em" }}
                    body={colorColBody}
                  ></Column>
                )}

                {simpleColsJson.map((item: any, index: number) => (
                  <Column
                    key={index}
                    className="h-[50px] border-t border-gray-200"
                    field={item.field}
                    header={item.header}
                    body={(data: any) => baseBody(data, item.field)}
                  ></Column>
                ))}

                {dateCol && (
                  <Column
                    className="h-[50px] border-t border-gray-200"
                    field={dateColData.field}
                    header={dateColData.header}
                    body={(data: any) => dateBody(data, dateColData.field)}
                  ></Column>
                )}

                {initialCol && (
                  <Column
                    className="h-[50px] border-t border-gray-200"
                    field={initialColData.field}
                    header={initialColData.header}
                    body={initialMBody}
                  ></Column>
                )}

                {stateCol && (
                  <Column
                    className="h-[50px] border-t border-gray-200"
                    field={stateColData.field}
                    header={stateColData.header}
                    body={stateBody}
                  ></Column>
                )}

                {detailCol && (
                  <Column
                    className="h-[50px] border-t border-gray-200"
                    field=""
                    header="جزئیات"
                    body={detailBody}
                  ></Column>
                )}
              </DataTable>
            </>
          ) : (
            <>
              <div className="flex w-full justify-center items-center p-10">
                <p className="text-gray-900">اطلاعاتی موجود نمیباشد</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MainTable;
